<template>
    <div>
        <custom-header title="Register">
            <br>
            <b-form @submit="sendRegistration" ref="auth-wrapper" class="auth-wrapper">
                <b-alert variant="danger" dismissable v-model="error.show">{{ error.message }}</b-alert>

                <b-input-group>
                    <b-input-group-prepend class="input-prepend" is-text>Email</b-input-group-prepend>
                    <b-form-input id="email" type="email" v-model="email" :disabled="disabled_input"
                                  :state="email_valid" required></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback :state="email_valid || email.length === 0">
                    Please enter a valid email address
                </b-form-invalid-feedback>

                <b-input-group>
                    <b-input-group-prepend class="input-prepend" is-text>Username</b-input-group-prepend>
                    <b-form-input v-model="username" :disabled="disabled_input" :state="username_valid"
                                  required></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback :state="username_valid || username.length === 0">
                    {{ username_error_text }}
                </b-form-invalid-feedback>

                <b-input-group>
                    <b-input-group-prepend class="input-prepend" is-text>Password</b-input-group-prepend>
                    <b-form-input :type="password_visible ? 'text' : 'password'" v-model="password"
                                  :disabled="disabled_input" :state="password_valid" required></b-form-input>
                    <b-input-group-append class="password-eye" is-text tabindex="0"
                                          @click="password_visible = !password_visible">
                        <font-awesome-icon :icon="password_visible ? faEyeSlash : faEye"/>
                    </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback :state="password_valid || password.length === 0">
                    Enter at least 8 letters
                </b-form-invalid-feedback>

                <b-form-checkbox name="agreements" :disabled="disabled_input" required>I have read and agree to the
                    <router-link target="_blank" to="/termsofuse" decorated>terms of use</router-link>
                    and
                    <router-link target="_blank" to="/privacypolicy" decorated>privacy policy</router-link>
                </b-form-checkbox>
                <b-form-checkbox :disabled="disabled_input" v-model="rememberme" :checked="true">Keep me logged in
                </b-form-checkbox>

                <b-button :disabled="disabled_input" type="submit" variant="info">Register</b-button>
            </b-form>

            <p id="text-or">or</p>

            <div class="other-signin">
                <span id="auth-discord-wrapper" tabindex="0">
                    <b-button disabled id="auth-discord"><font-awesome-icon
                        :icon="faDiscord"/> Sign in with Discord</b-button>
                </span>
                <b-tooltip target="auth-discord-wrapper">Not yet implemented</b-tooltip>

                <span id="auth-google-wrapper" tabindex="0">
                    <b-button disabled variant="light" id="auth-google">
                        <font-awesome-icon :icon="faGoogle"/>
                        Sign in with Google
                    </b-button>
                </span>
                <b-tooltip target="auth-google-wrapper">Not yet implemented</b-tooltip>
            </div>
        </custom-header>
        <br>
        <div>
            <div class="g-recaptcha" data-badge="bottom-right" id="recaptcha"></div>
        </div>
    </div>
</template>

<script>
import CustomHeader from "@/components/templates/CustomHeader";
import {
    BAlert,
    BForm,
    BFormInput,
    BInputGroup,
    BButton,
    BFormCheckbox,
    BTooltip,
    BFormInvalidFeedback, BInputGroupAppend, BInputGroupPrepend
} from "bootstrap-vue";
import FontAwesomeIcon from "@fortawesome/vue-fontawesome/src/components/FontAwesomeIcon";

import {faDiscord, faGoogle} from '@fortawesome/free-brands-svg-icons';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

export default {
    name: "Register",
    metaInfo: {
        title: "Register"
    },
    components: {
        CustomHeader,
        BAlert,
        BForm,
        BFormInput,
        BInputGroup,
        BButton,
        FontAwesomeIcon,
        BFormCheckbox,
        BTooltip,
        BFormInvalidFeedback,
        BInputGroupPrepend,
        BInputGroupAppend
    },
    data() {
        return {
            faGoogle,
            faDiscord,
            faEye,
            faEyeSlash,
            email: "",
            username: "",
            password: "",
            rememberme: true,
            disabled_input: false,
            error: {
                show: false,
                message: "username already taken"
            },
            password_visible: false,
        }
    },
    computed: {
        username_valid() {
            return /^[\d\w_.]{4,16}$/.test(this.username);
        },
        email_valid() {
            return /^([^\s]+)@([^\s]+){2,}\.([^\s]+){2,}$/.test(this.email);
        },
        password_valid() {
            return this.password.length > 8;
        },

        username_error_text() {
            if (!/^[\d\w_.]*$/.test(this.username))
                return "The username may only contain: A-Z, 0-9, _ and .";
            return "The username must be between 4 and 16 characters long."
        }
    },
    methods: {
        sendRegistration(e) {
            e.preventDefault();

            const vue = this;
            window.grecaptcha.ready(function () {
                window.grecaptcha.execute(vue.$config.grecaptcha_key, {action: 'submit'}).then(token => {
                    vue.disabled_input = true;
                    console.log(`${vue.$config.backend_host}/api/auth/register`)
                    axios.post(`${vue.$config.backend_host}/api/auth/register`, {
                        grecaptcha_token: token,
                        email: vue.email,
                        username: vue.username,
                        password: vue.password
                    }).then(res => {
                        console.log(res)
                        if (res.data.success) {
                            window.grecaptcha.execute(vue.$config.grecaptcha_key, {action: 'submit'}).then(token => {
                                axios.post(`${vue.$config.backend_host}/api/auth/login`, {
                                    grecaptcha_token: token,
                                    email_username: vue.email,
                                    password: vue.password
                                }).then(res => {
                                    console.log(res)
                                    if (res.data.success) {
                                        vue.$store.commit("auth/set_authentication", {
                                            rememberme: vue.rememberme,
                                            auth: res.data
                                        });
                                        vue.$router.push(vue.$route.query.redirect ? vue.$route.query.redirect : vue.$config.login_redirect);
                                    } else {
                                        vue.error.show = true;
                                        vue.error.message = res.data.message;
                                        vue.disabled_input = false;
                                    }
                                })
                            });
                        } else {
                            vue.error.show = true;
                            vue.error.message = res.data.message;
                            vue.disabled_input = false;
                        }
                    });
                });
            });
        }
    }
}
</script>

<style scoped>
.password-eye .input-group-text {
    transition: all .2s ease;
    cursor: pointer;
}

.password-eye:hover .input-group-text {
    background-color: #adafb1;
    border-color: #adafb1;
}

.auth-wrapper {
    width: min(80%, 30em);
    margin-right: auto;
    margin-left: auto;
}

.auth-wrapper > * + * {
    margin-top: 1em;
}

.auth-wrapper .input-prepend > div {
    width: min(25vw, 6.5em);
}

#auth-discord {
    background-color: #7289DA;
    border-color: transparent;
}

#auth-discord:hover:not([disabled]) {
    background-color: #485688;
}

#text-or {
    margin-top: 2em;
}

.other-signin {
    margin-top: 2em;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.other-signin > span {
    width: min(80%, 16em);
    display: inline-block;
}

.other-signin .btn {
    width: 100%;
    display: block;
}

.other-signin > * + * {
    margin-top: 1em;
}

.other-signin > .btn svg {
    margin-right: .5em;
}

</style>